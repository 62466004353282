import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Select } from 'antd';
import {
  editCompanySucceeded,
  getProductPortfolioList,
  initSearchCompanyListByProducts,
  searchCompanyListByProducts,
} from 'actions/companiesActions';
import { RootState } from 'reducers/rootReducer';
import { Company } from 'reducers/companies/types';
import MainLayout, { MenuType } from 'layouts/MainLayout/MainLayout';
import Loader from 'components/Loader/Loader';
import CompanyListCard from 'components/CompanyListCard/CompanyListCard';
import CompanyDrawer from 'components/CompanyDrawer/CompanyDrawer';
import './companies-page.scss';

const { Option } = Select;

const ProductCompaniesPage: FC = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [usedBefore, setUsedBefore] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    dispatch(initSearchCompanyListByProducts());
  }, []);

  const {
    companyListIsFetching,
    productPortfolioList,
    productFilteredCompanies,
  } = useSelector(({ companies }: RootState) => companies);

  const mappedData: any[] = [];
  productPortfolioList.forEach((d) => {
    mappedData.push(<Option key={d._id} value={d.name}>{d.name}</Option>);
  });

  const onSelect = (_: any, option: { key: string }) => {
    setUsedBefore(true);
    setFilters([...filters, option.key]);
    setFilterValue('');
  };

  const onDeselect = (_: any, option: { key: string }) => {
    setFilters(filters.filter((item) => item !== option.key));
  };

  useEffect(() => {
    if (usedBefore) {
      dispatch(searchCompanyListByProducts(filters));
    }
  }, [filters, searchCompanyListByProducts, dispatch]);

  return (
    <MainLayout selectedMenu={MenuType.PRODUCT_COMPANIES}>
      <div className="flbx-companies-container">
        <div className="company-list-header center">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            onSearch={(value) => {
              setFilterValue(value);
              if (value && value.length >= 3) {
                dispatch(getProductPortfolioList(value));
              }
            }}
            notFoundContent={null}
            filterOption={false}
            searchValue={filterValue}
            placeholder="Keress a cégek között termékek alapján"
            className="product-company-search-dropdown"
            onSelect={onSelect}
            onDeselect={onDeselect}
          >
            {mappedData}
          </Select>
        </div>
        <div className="company-list">
          <Loader isLoading={companyListIsFetching} pageLoader>
            {
              !usedBefore
              && (
                <div className="empty-block">A kereséshez válasszon legalább egy terméket</div>
              )
            }
            {
              usedBefore && productFilteredCompanies && !productFilteredCompanies.length
              && (
                <div className="empty-block">Nincs megjelenítendő cég</div>
              )
            }
          </Loader>
          <Row gutter={{ xs: 8, sm: 18, md: 4, lg: 20 }}>
            {
              productFilteredCompanies.map((companyCard) => (
                <Col
                  key={companyCard._id}
                  span={6}
                  xl={6}
                  lg={8}
                  md={12}
                  sm={18}
                  xs={24}
                  style={{ display: 'flex' }}
                >
                  <CompanyListCard
                    companyName={companyCard.companyName}
                    logoSrc={companyCard.logo}
                    companyServices={companyCard.services}
                    onClick={() => {
                      dispatch(editCompanySucceeded());
                      setSelectedCompany(companyCard);
                      setVisible(!visible);
                    }}
                  />
                </Col>
              ))
            }
          </Row>
        </div>
        <CompanyDrawer
          companyDetails={selectedCompany}
          visible={visible}
          onOpen={(visibility) => {
            if (!visibility) {
              setTimeout(() => {
                setSelectedCompany(null);
              }, 300);
            }
            setVisible(visibility);
          }}
        />
      </div>
    </MainLayout>
  );
};

export default ProductCompaniesPage;

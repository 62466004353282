import { takeEvery, takeLatest, put, call, select, PutEffect, CallEffect } from 'redux-saga/effects';
import * as TYPES from 'consts/companiesActionTypes';
import * as API from 'services/api';
import { Company, CompanyServiceDto, DropdownEntry, PostCreateCompany } from 'reducers/companies/types';
import { RootState } from 'reducers/rootReducer';
import { CompaniesState } from 'reducers/companies/initialState';

export const getCompanyStore = ({ companies }: RootState): CompaniesState => (companies);

export function* getCompanyList(): Generator<CallEffect | PutEffect> {
  try {
    const query = {
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const companyList = yield call(API.findDataService, 'company-profiles', '/company-profiles/', query);
    yield put({
      type: TYPES.COMPANIES_GET_COMPANY_LIST_S,
      payload: {
        companyList,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.COMPANIES_GET_COMPANY_LIST_F,
      payload: {
        companyFetchingError: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getCompany(action: TYPES.GetCompanyRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { id } = action.payload;
    const query = {
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const company = yield call(API.findDataService, 'company-profiles', `/company-profiles/${id}`, query);
    yield put({
      type: TYPES.GET_COMPANY_S,
      payload: {
        company,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.GET_COMPANY_F,
      payload: {
        companyFetchingError: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createCompany(action: TYPES.CreateCompanyRequestedType): any {
  try {
    const { formData } = action.payload;
    const { createCompany: company } = yield select(getCompanyStore);
    let data = formData as PostCreateCompany;
    if (company.companyServices) {
      data = {
        ...data,
        companyServices: company.companyServices.map((item: { key: string }) => item.key) as string[],
      };
    }
    yield call(API.createDataService, 'company-profiles', '/company-profiles/', { ...data });
    yield put({
      type: TYPES.COMPANIES_CREATE_COMPANY_S,
    });
    yield call(getCompanyList);
  } catch (error) {
    yield put({
      type: TYPES.COMPANIES_CREATE_COMPANY_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* editCompany(action: TYPES.EditCompanyRequestedType): any {
  try {
    const { formData } = action.payload;
    const { company } = yield select(getCompanyStore);
    let data = formData as PostCreateCompany;
    if (company.companyServices) {
      data = {
        ...data,
        companyServices: company.companyServices.map((item: { key: string }) => item.key) as string[],
      };
    }
    yield call(API.patchDataService, 'company-profiles', '/company-profiles', company._id, { ...data });
    yield put({
      type: TYPES.COMPANIES_EDIT_COMPANY_S,
    });
    yield call(getCompanyList);
  } catch (error) {
    yield put({
      type: TYPES.COMPANIES_EDIT_COMPANY_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getCompanyServiceList(action: TYPES.GetCompanyServiceListRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const query = {
      $params: JSON.stringify({
        name: { $regex: action.payload.name },
      }),
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const companyServices = yield call(API.findDataService, 'company-services', '/company-services/', query);
    const companyServiceList = (companyServices as CompanyServiceDto[]).map((item) => ({
      key: item.id,
      label: item.name,
    } as DropdownEntry));
    yield put({
      type: TYPES.GET_COMPANY_SERVICE_LIST_S,
      payload: {
        companyServiceList,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.GET_COMPANY_SERVICE_LIST_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createCompanyService(action: TYPES.CreateCompanyServiceRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { companyService, type } = action.payload;
    const createdCompanyService = yield call(API.createDataService, 'company-services', '/company-services/', companyService);
    yield put({
      type: TYPES.CREATE_COMPANY_SERVICE_S,
      payload: {
        companyService: createdCompanyService,
        type,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.CREATE_COMPANY_SERVICE_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* getProductPortfolio(action?: TYPES.GetProductPortfolioListRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const query = {
      $params: JSON.stringify({
        name: { $regex: action?.payload.name || '' },
      }),
      $sort: JSON.stringify({
        createdAt: -1,
      }),
    };
    const productPortfolioList = yield call(API.findDataService, 'product-portfolio', '/product-portfolio/', query);
    yield put({
      type: TYPES.GET_PRODUCT_PORTFOLIO_LIST_S,
      payload: {
        productPortfolioList,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.GET_PRODUCT_PORTFOLIO_LIST_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* createProductPortfolio(action: TYPES.CreateProductPortfolioRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { productPortfolio: data } = action.payload;
    const productPortfolio = yield call(API.createDataService, 'product-portfolio', '/product-portfolio/', {
      name: data.name,
      keywords: data.keywords.filter((item) => item),
    });
    yield put({
      type: TYPES.CREATE_PRODUCT_PORTFOLIO_S,
      payload: {
        productPortfolio,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.CREATE_PRODUCT_PORTFOLIO_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* editProductPortfolio(action: TYPES.EditProductPortfolioRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { productPortfolio: data } = action.payload;
    const productPortfolio = yield call(API.patchDataService, 'product-portfolio', '/product-portfolio', data._id, {
      name: data.name,
      keywords: data.keywords.filter((item) => item),
    });
    yield put({
      type: TYPES.EDIT_PRODUCT_PORTFOLIO_S,
      payload: {
        productPortfolio,
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.EDIT_PRODUCT_PORTFOLIO_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* searchCompanyListByProducts(action: TYPES.SearchCompanyListRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { productPortfolioIds } = action.payload;
    const productFilteredCompanies = yield call(API.createDataService, 'company-profiles', '/company-profiles/search', { productPortfolioIds });
    yield put({
      type: TYPES.COMPANIES_SEARCH_COMPANY_LIST_S,
      payload: {
        productFilteredCompanies: (productFilteredCompanies as Company[]).filter((item) => !item.deprecated),
      },
    });
  } catch (error) {
    yield put({
      type: TYPES.COMPANIES_SEARCH_COMPANY_LIST_F,
      payload: {
        companyFetchingError: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export function* deleteProductPortfolio(action: TYPES.DeleteProductPortfolioRequestedType): Generator<CallEffect | PutEffect> {
  try {
    const { id } = action.payload;
    yield call(API.deleteDataService, 'product-portfolio', '/product-portfolio', id);
    yield put({
      type: TYPES.DELETE_PRODUCT_PORTFOLIO_S,
    });
    yield call(getProductPortfolio);
  } catch (error) {
    yield put({
      type: TYPES.DELETE_PRODUCT_PORTFOLIO_F,
      payload: {
        errorMessage: (error as Error).message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const companiesSagas = [
  takeEvery(TYPES.COMPANIES_GET_COMPANY_LIST_R, getCompanyList),
  takeEvery(TYPES.COMPANIES_CREATE_COMPANY_R, createCompany),
  takeLatest(TYPES.GET_COMPANY_SERVICE_LIST_R, getCompanyServiceList),
  takeEvery(TYPES.CREATE_COMPANY_SERVICE_R, createCompanyService),
  takeEvery(TYPES.GET_COMPANY_R, getCompany),
  takeEvery(TYPES.COMPANIES_EDIT_COMPANY_R, editCompany),
  takeLatest(TYPES.GET_PRODUCT_PORTFOLIO_LIST_R, getProductPortfolio),
  takeLatest(TYPES.CREATE_PRODUCT_PORTFOLIO_R, createProductPortfolio),
  takeLatest(TYPES.EDIT_PRODUCT_PORTFOLIO_R, editProductPortfolio),
  takeLatest(TYPES.COMPANIES_SEARCH_COMPANY_LIST_R, searchCompanyListByProducts),
  takeLatest(TYPES.DELETE_PRODUCT_PORTFOLIO_R, deleteProductPortfolio),
];

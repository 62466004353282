import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownEntry } from 'reducers/companies/types';
import CompanyServiceSelect from 'components/CompanyServiceSelect/CompanyServiceSelect';
import InputBlock from 'components/InputBlock/InputBlock';

interface CompanyServicesBlockProps {
  data: Array<DropdownEntry>;
  initialValue: Array<DropdownEntry>;
  onSearch?: (event: any) => void;
  onBlur?: (event: any) => void;
  onSelect?: (value: any, option: any) => void;
  onDeselect?: (value: any, option: any) => void;
  fixHeight?: boolean;
}

const CompanyServicesBlock: FC<CompanyServicesBlockProps> = ({
  data,
  initialValue,
  onSearch = () => { },
  onBlur = () => { },
  onSelect = () => { },
  onDeselect = () => { },
  fixHeight = true,
}) => {
  const { t } = useTranslation();

  return (
    <InputBlock title={t('pages.companies.companyServices')} fixHeight={fixHeight}>
      <CompanyServiceSelect
        onBlur={onBlur}
        onSearch={onSearch}
        onSelect={onSelect}
        onDeselect={onDeselect}
        initialValue={initialValue}
        data={data}
      />
    </InputBlock>
  );
};

CompanyServicesBlock.defaultProps = {
  onSearch: () => { },
  onBlur: () => { },
  onSelect: () => { },
  onDeselect: () => { },
};

export default CompanyServicesBlock;

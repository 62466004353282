import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout/MainLayout';
import {
  addServiceToCompany,
  createCompanyService,
  deleteServiceFromCompany,
  editCompany,
  getCompany,
  getCompanyServiceList,
} from 'actions/companiesActions';
import { getUsers } from 'actions/userActions';
import Loader from 'components/Loader/Loader';
import TabView from 'components/TabView/TabView';
import { CompanyContact, CompanyDetails, DropdownEntry, PersonalContact, ProductPortfolioDto } from 'reducers/companies/types';
import { RootState } from 'reducers/rootReducer';
import NavigationRoute from 'routes/NavigationRoute';
import CompanyAddressBlock from './blocks/CompanyAddressBlock';
import CompanyContactsBlock from './blocks/CompanyContactsBlock';
import PersonalContactsBlock from './blocks/PersonalContactsBlock';
import CompanyServicesBlock from './blocks/CompanyServicesBlock';
import CompanyLocationBlock from './blocks/CompanyLocationBlock';
import CompanyImageUploadBlock from './blocks/CompanyImageUploadBlock';
import UsersBlock from './blocks/UsersBlock';
import ProductsBlock from './blocks/ProductsBlock';

export enum Tabs {
  BASICS = 'BASICS',
  COMPANY_CONTACTS = 'COMPANY_CONTACTS',
  CONTACTS = 'CONTACTS',
  SERVICES = 'SERVICES',
  PRODUCTS = 'PRODUCTS',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  LOCATION = 'LOCATION',
  USERS = 'USERS',
}

const EditCompanyPage: FC = () => {
  const params = useParams();
  const editedCompanyId = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    companyEditSucceeded,
    companyListIsFetching,
    company,
    companyServiceList,
    companyFetched,
    companyEditLoading,
    productPortfolioSucceeded,
    productPortfolio,
  } = useSelector(({ companies }: RootState) => companies);
  const { users } = useSelector(({ user }: RootState) => user);

  useEffect(() => {
    if (editedCompanyId) {
      dispatch(getCompany(editedCompanyId));
    }
  }, [editedCompanyId]);

  const initialState: CompanyDetails = {
    companyName: '',
    addressCountry: 'Magyarország',
    addressZip: '',
    addressCity: '',
    addressStreet: '',
    addressHouseNumber: '',
    addressFloor: '',
    addressDoor: '',
    addressDoorbell: '',
    vatId: '',
    website: '',
    logo: null,
    placeImage: null,
    geoLocation: {
      lat: 30,
      lng: 40,
    },
    companyServices: [],
    productPortfolios: [],
  };

  const [disabled, setDisabled] = useState(false);
  const [companyData, setCompanyData] = useState(initialState);
  const [initialCompanyData, setInitialCompanyData] = useState(initialState);
  const [companyContacts, setCompanyContacts] = useState<CompanyContact[]>([]);
  const [initialCompanyContacts, setInitialCompanyContacts] = useState<CompanyContact[]>([]);
  const [personalContacts, setPersonalContacts] = useState<PersonalContact[]>([]);
  const [initialPersonalContacts, setInitialPersonalContacts] = useState<PersonalContact[]>([]);
  const [selectedTab, setSelectedTab] = useState<{ title: string, id: string }>({ title: 'Alapadatok', id: Tabs.BASICS });
  const tabs: { title: string, id: string }[] = [
    { title: 'Alapadatok', id: Tabs.BASICS },
    { title: 'Céges kapcsolattartás', id: Tabs.COMPANY_CONTACTS },
    { title: 'Felhasználók', id: Tabs.USERS },
    { title: 'Kapcsolattartók', id: Tabs.CONTACTS },
    { title: 'Szolgáltatások', id: Tabs.SERVICES },
    { title: 'Termékek', id: Tabs.PRODUCTS },
    { title: 'Képfeltöltés', id: Tabs.IMAGE_UPLOAD },
    { title: 'Lokáció', id: Tabs.LOCATION },
  ];

  useEffect(() => {
    if (companyEditSucceeded) {
      setCompanyData(initialState);
      setPersonalContacts([]);
      navigate(NavigationRoute.Companies);
    }
  }, [companyEditSucceeded]);

  useEffect(() => {
    if (companyFetched && company) {
      setCompanyData({
        companyName: company.companyName,
        addressCountry: 'Magyarország',
        addressZip: company.address.zip ? company.address.zip.toString() : '',
        addressCity: company.address.city || '',
        addressStreet: company.address.street || '',
        addressHouseNumber: company.address.houseNumber || '',
        addressFloor: company.address.floor || '',
        addressDoor: company.address.door || '',
        addressDoorbell: company.address.doorbell || '',
        website: company.website || '',
        vatId: company.vatId || '',
        logo: company.logo || null,
        placeImage: company.placeImage || null,
        geoLocation: {
          lat: company.geoLocation.lat,
          lng: company.geoLocation.lng,
        },
        companyServices: company.companyServices,
        productPortfolios: company.productPortfolios,
      });
      setInitialCompanyData({
        companyName: company.companyName,
        addressCountry: 'Magyarország',
        addressZip: company.address.zip ? company.address.zip.toString() : '',
        addressCity: company.address.city || '',
        addressStreet: company.address.street || '',
        addressHouseNumber: company.address.houseNumber || '',
        addressFloor: company.address.floor || '',
        addressDoor: company.address.door || '',
        addressDoorbell: company.address.doorbell || '',
        website: company.website || '',
        vatId: company.vatId || '',
        logo: company.logo || null,
        placeImage: company.placeImage || null,
        geoLocation: {
          lat: company.geoLocation.lat,
          lng: company.geoLocation.lng,
        },
        companyServices: company.companyServices,
        productPortfolios: company.productPortfolios,
      });
      setCompanyContacts(company.companyContacts.map((item) => ({
        label: item.label || '',
        phone: item.phone || '',
        email: item.email || '',
      })));
      setInitialCompanyContacts(company.companyContacts.map((item) => ({
        label: item.label || '',
        phone: item.phone || '',
        email: item.email || '',
      })));
      setPersonalContacts(company.personalContacts.map((item) => ({
        name: item.name || '',
        phone: item.phone || '',
        email: item.email || '',
        role: item.role || '',
      })));
      setInitialPersonalContacts(company.personalContacts.map((item) => ({
        name: item.name || '',
        phone: item.phone || '',
        email: item.email || '',
        role: item.role || '',
      })));
      dispatch(getUsers(company._id));
    }
  }, [companyFetched]);

  useEffect(() => {
    if (!isEqual(companyData, initialCompanyData) || !isEqual(companyContacts, initialCompanyContacts) || !isEqual(personalContacts, initialPersonalContacts)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [companyData, companyContacts, personalContacts]);

  const handleAddCompanyContact = () => {
    setCompanyContacts(() => ([
      ...companyContacts,
      {
        label: '',
        email: '',
        phone: '',
      },
    ]));
  };

  const handleAddContact = () => {
    setPersonalContacts(() => ([
      ...personalContacts,
      {
        name: '',
        role: 'ügyvezető',
        email: '',
        phone: '',
      },
    ]));
  };

  const handleDeleteCompanyContact = (selectedContactIndex: number) => {
    setCompanyContacts(() => (
      companyContacts.filter((_, index) => (index !== selectedContactIndex))
    ));
  };

  const handleDeleteContact = (selectedContactIndex: number) => {
    setPersonalContacts(() => (
      personalContacts.filter((_, index) => (index !== selectedContactIndex))
    ));
  };

  const handleChangeCompanyContact = (selectedContactIndex: number, field: string, value: string) => {
    setCompanyContacts(() => (
      companyContacts.map((contact, index) => {
        if (index === selectedContactIndex) {
          return {
            ...contact,
            [field]: value,
          };
        }

        return contact;
      })
    ));
  };

  const handleContactChange = (selectedContactIndex: number, field: string, value: string) => {
    setPersonalContacts(() => (
      personalContacts.map((contact, index) => {
        if (index === selectedContactIndex) {
          return {
            ...contact,
            [field]: value,
          };
        }

        return contact;
      })
    ));
  };

  const handleInputChange = (value: string | object, field: string) => {
    setCompanyData((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const handleSubmitForm = () => {
    dispatch(editCompany({
      companyName: companyData.companyName,
      address: {
        zip: companyData.addressZip,
        city: companyData.addressCity,
        street: companyData.addressStreet,
        houseNumber: companyData.addressHouseNumber,
        floor: companyData.addressFloor,
        door: companyData.addressDoor,
        doorbell: companyData.addressDoorbell,
      },
      vatId: companyData.vatId,
      geoLocation: companyData.geoLocation,
      logo: companyData.logo,
      placeImage: companyData.placeImage,
      website: companyData.website,
      companyContacts,
      personalContacts,
      productPortfolios: companyData.productPortfolios,
    }));
  };

  const handleOnSelect = (value: any, option: any) => {
    if (!('key' in option)) {
      dispatch(createCompanyService({ approved: false, name: value, id: null }, 'edit'));
    } else {
      dispatch(addServiceToCompany({ key: option.key, label: option.value } as DropdownEntry, 'edit'));
    }
    setDisabled(false);
  };

  const handleOnDeSelect = (value: any, option: any) => {
    dispatch(deleteServiceFromCompany({ key: option.key, label: option.value } as DropdownEntry, 'edit'));
    setDisabled(false);
  };

  const handleOnSearch = (event: any) => {
    if (event.length > 2) {
      dispatch(getCompanyServiceList(event));
    }
  };

  const handleOnBlur = (event: any) => {
    if (event.target.value.length >= 3) {
      dispatch(createCompanyService({ approved: false, name: event.target.value, id: null }, 'edit'));
      setDisabled(false);
    }
  };

  const createUrl = (imageSource: string | null, type: string) => {
    const S3 = process.env.REACT_APP_S3_URL;
    if (!imageSource) return null;
    return S3 && imageSource.includes(S3) ? imageSource : `${S3}/${type}/${imageSource}`;
  };

  const onSelectPortfolio = (selectedPortfolio: ProductPortfolioDto) => {
    setCompanyData({
      ...companyData,
      productPortfolios: [...companyData.productPortfolios, selectedPortfolio],
    });
  };

  const onDeselectPortfolio = (id: string) => {
    setCompanyData({
      ...companyData,
      productPortfolios: companyData.productPortfolios.filter((item) => item._id !== id),
    });
  };

  useEffect(() => {
    if (productPortfolioSucceeded && productPortfolio) {
      onSelectPortfolio(productPortfolio);
    }
  }, [productPortfolioSucceeded]);

  return (
    <MainLayout
      customBodyStyle={{ paddingBottom: 90 }}
      leftPanelClassName="flbx-custom-left-panel"
      menuClassName="flbx-custom-menu"
    >
      <TabView
        buttonTitle="Változások mentése"
        disabled={disabled}
        onClick={handleSubmitForm}
        loading={companyEditLoading}
        title={company?.companyName || ''}
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={(tab) => setSelectedTab(tab)}
        content={(
          <Loader isLoading={companyListIsFetching} pageLoader>
            {
              {
                [Tabs.BASICS]: (
                  <CompanyAddressBlock
                    createCompany={companyData}
                    handleInputChange={handleInputChange}
                    onChange
                  />
                ),
                [Tabs.COMPANY_CONTACTS]: (
                  <CompanyContactsBlock
                    website={companyData.website}
                    contacts={companyContacts}
                    handleAddContact={handleAddCompanyContact}
                    handleDeleteContact={handleDeleteCompanyContact}
                    handleContactChange={handleChangeCompanyContact}
                    handleInputChange={handleInputChange}
                    fixHeight={false}
                  />
                ),
                [Tabs.USERS]: (
                  <UsersBlock
                    users={users}
                    companyId={editedCompanyId}
                  />
                ),
                [Tabs.CONTACTS]: (
                  <PersonalContactsBlock
                    contacts={personalContacts}
                    handleAddContact={handleAddContact}
                    handleDeleteContact={handleDeleteContact}
                    handleContactChange={handleContactChange}
                    fixHeight={false}
                  />
                ),
                [Tabs.IMAGE_UPLOAD]: (
                  <div>
                    <CompanyImageUploadBlock
                      title="Céglogo"
                      type="LOGO"
                      fieldName="logo"
                      imageSize={180}
                      onImageChange={handleInputChange}
                      imageSource={createUrl(companyData.logo, 'logos')}
                    />
                    <CompanyImageUploadBlock
                      title="Bejárat"
                      type="PLACE"
                      fieldName="placeImage"
                      imageSize={320}
                      onImageChange={handleInputChange}
                      imageSource={createUrl(companyData.placeImage, 'places')}
                    />
                  </div>
                ),
                [Tabs.SERVICES]: (
                  <CompanyServicesBlock
                    onBlur={handleOnBlur}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    onDeselect={handleOnDeSelect}
                    initialValue={
                      company?.companyServices.length
                        ? company?.companyServices.map((item) => ({ key: item.key || '', label: item.label || '' }))
                        : []
                    }
                    data={companyServiceList}
                    fixHeight={false}
                  />
                ),
                [Tabs.PRODUCTS]: (
                  <ProductsBlock
                    data={companyData.productPortfolios}
                    onSelectPortfolio={onSelectPortfolio}
                    onDeselectPortfolio={onDeselectPortfolio}
                    fixHeight={false}
                  />
                ),
                [Tabs.LOCATION]: (
                  <CompanyLocationBlock
                    defaultPosition={{
                      lat: company?.geoLocation.lat || 47.513583,
                      lng: company?.geoLocation.lng || 19.2843238,
                    }}
                    companyProfile={company}
                    onMarkerChange={handleInputChange}
                  />
                ),
              }[selectedTab.id as Tabs]
            }
          </Loader>
        )}
      />
    </MainLayout>
  );
};

export default EditCompanyPage;

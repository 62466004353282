import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { Input, Select } from '@flexboxapps/flbx-webapp-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { PersonalContact as PersonalContactType } from 'reducers/companies/types';
import './personal-contact.scss';

interface PersonalContactProps {
  index: number;
  contact: PersonalContactType;
  handleContactChange: (selectedContactIndex: number, field: string, value: string) => void;
  handleDeleteContact: (index: number) => void;
}

// TODO: add more types + get them from API
const contactTypes = [
  { label: 'ügyvezető', value: 'ügyvezető' },
];

const PersonalContact: FC<PersonalContactProps> = ({
  index,
  contact,
  handleContactChange,
  handleDeleteContact,
}) => {
  return (
    <div className="company-contact">
      <div className="company-contact-body">
        <div>{`Kapcsolattartó #${index + 1}`}</div>
        <Row gutter={{ xs: 8, sm: 16 }}>
          <Col span={12}>
            <Input
              label="kapcsolattartó neve"
              value={contact.name}
              placeholder=""
              onChange={(e) => {
                handleContactChange(index, 'name', e.target.value);
              }}
            />
          </Col>
          <Col span={12}>
            <Select
              label="kapcsolattartó"
              placeholder="kapcsolattartó"
              value={contact.role}
              options={contactTypes.map(({ label, value }) => ({ label, value }))}
              onChange={(value) => {
                if (value) {
                  handleContactChange(index, 'role', value.toString());
                }
              }}
              size="large"
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16 }}>
          <Col span={12}>
            <Input
              label="email"
              value={contact.email}
              placeholder=""
              onChange={(e) => {
                handleContactChange(index, 'email', e.target.value);
              }}
            />
          </Col>
          <Col span={12}>
            <Input
              label="telefon"
              value={contact.phone}
              placeholder=""
              onChange={(e) => {
                handleContactChange(index, 'phone', e.target.value);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="company-contact-remove">
        <FontAwesomeIcon
          icon={faTrashAlt}
          onClick={() => handleDeleteContact(index)}
          size="2x"
          color="#E74C3C"
        />
      </div>
    </div>
  );
};

export default PersonalContact;
